import React, { useEffect } from "react";
import { Link } from "react-router";

import { TEST_ID_CASE_LIST_LINK } from "../components/dialogs/SaveReportDialog";
import PageHeader from "../components/pages/PageHeader";
import { SITE_TITLE } from "../helpers/strings";

export const TEST_ID_ERROR_MESSAGE = "ErrorMessage";

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  message?: string;
}

const ErrorPage = ({ title, subtitle, message }: ErrorPageProps): React.JSX.Element => {
  useEffect(() => {
    document.title = `Error | ${SITE_TITLE}`;
  }, []);

  return (
    <div className="container is-max-widescreen">
      <PageHeader title={title ?? "Error"} subtitle={subtitle} />
      <div className="columns content">
        <div className="column is-two-thirds is-half-widescreen">
          <p>An unexpected error occurred while processing your request.</p>
          {message && <p data-testid={TEST_ID_ERROR_MESSAGE}>{message}</p>}
          <p>
            Please check the browser address bar to ensure that there are no incorrect or
            missing characters in the URL, or{" "}
            <Link to="/" data-testid={TEST_ID_CASE_LIST_LINK}>
              return to the case list
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
