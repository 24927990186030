import React from "react";
import { useNavigate } from "react-router";

export const TEST_ID_TEST_MODE_DIALOG_HEADING = "TestModeDialogHeading";
export const TEST_ID_TRY_MICRO_REPORT_BUILDER_BUTTON = "TryMicroReportBuilderButton";
export const TEST_ID_RETURN_TO_CASES_LINK = "ReturnToCasesLink";

interface TestModeDialogProps {
  setShowTestModeDialog: (x: boolean) => void;
}

const TestModeDialog = ({
  setShowTestModeDialog,
}: TestModeDialogProps): React.JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="modal is-active">
      <div
        className="modal-background"
        onClick={() => setShowTestModeDialog(false)}
      ></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box content has-background-grey-lighter">
          <h3 className="title is-4" data-testid={TEST_ID_TEST_MODE_DIALOG_HEADING}>
            Micro Report Builder
          </h3>
          <p>
            Try PathKit’s Micro Report Builder - you will only see some of PathKit’s
            features and won’t be able to save.
          </p>
          <div className="buttons">
            <button
              type="button"
              className="button is-primary"
              onClick={() => setShowTestModeDialog(false)}
              data-testid={TEST_ID_TRY_MICRO_REPORT_BUILDER_BUTTON}
            >
              Try Micro Report Builder
            </button>
            <button
              type="button"
              className="button"
              onClick={() => navigate("/")}
              data-testid={TEST_ID_RETURN_TO_CASES_LINK}
            >
              Return to cases
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestModeDialog;
