import classNames from "classnames";
import React from "react";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

const PageHeader = ({ title, subtitle }: PageHeaderProps): React.JSX.Element => {
  return (
    <header className="hero">
      {/* This conditional stying can be removed after we have severed ties between the LIMS and PathKit */}
      <div
        className={classNames("hero-body px-0", {
          "pb-2": title !== "Cases" && title !== "Error" && title !== "PathKit",
        })}
      >
        <h1 className="title">{title}</h1>
        {!!subtitle && <h2 className="subtitle">{subtitle}</h2>}
      </div>
    </header>
  );
};

export default PageHeader;
