import React from "react";
import { CaseListTab } from "src/routes/CaseList";

export const TEST_ID_CASE_COUNT_HEADING = "CaseListCaseCount";

interface CaseCountProps {
  tab: CaseListTab;
  caseCount: number;
}

const CaseCount = ({ tab, caseCount }: CaseCountProps): React.JSX.Element => {
  const pageSize = 100;
  return (
    <h3 className="title is-size-4" data-testid={TEST_ID_CASE_COUNT_HEADING}>
      {tab === "Authorised" && caseCount > pageSize
        ? `${pageSize} most recent cases (of ${caseCount})`
        : `${caseCount} ${caseCount === 1 ? "case" : "cases"}`}
    </h3>
  );
};

export default CaseCount;
