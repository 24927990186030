import { useAuthenticator } from "@aws-amplify/ui-react";
import { setUpTOTP, updateMFAPreference, verifyTOTPSetup } from "aws-amplify/auth";
import { QRCodeSVG } from "qrcode.react";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { SITE_TITLE } from "../../helpers/strings";
import { AppDispatch } from "../../store";
import { setMfaEnabled } from "../../store/authSlice";

export const TEST_ID_MFA_SETUP_QR_CODE = "MfaSetupQrCode";
export const TEST_ID_MFA_SETUP_CONFIRM_BUTTON = "MfaSetupConfirmButton";
export const TEST_ID_MFA_SETUP_VALIDATION_ERROR = "MfaSetupValidationError";

// Friendly name displayed in authenticator app
const environment = import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development";
const getIssuer = (): string => {
  switch (environment) {
    case "local-development":
    case "staging":
      return "PathKit (staging)";
    case "pre-production":
      return "PathKit (pre-prod)";
    default:
      return SITE_TITLE;
  }
};

const ChallengeAnswerForm = (): React.JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  // Local state
  const [challengeAnswer, setChallengeAnswer] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setBusy(true);
    setFormError("");
    try {
      await verifyTOTPSetup({ code: challengeAnswer });
      await updateMFAPreference({ totp: "ENABLED" });
      dispatch(setMfaEnabled(true));
    } catch (error) {
      setBusy(false);
      setFormError(error instanceof Error ? error.message : "Failed to verify code");
    }
  };

  return (
    <>
      <form className="field has-addons mb-0" onSubmit={handleSubmit}>
        <p className="control">
          <input
            required
            type="text"
            maxLength={6}
            pattern="\d{6}"
            id="challengeAnswer"
            value={challengeAnswer}
            className="input"
            style={{ width: "10rem" }}
            placeholder="6-digit code"
            onChange={(e) => setChallengeAnswer(e.target.value)}
          />
        </p>
        <p className="control">
          <button
            type="submit"
            className={`button is-primary ${busy ? "is-loading" : ""}`}
            data-testid={TEST_ID_MFA_SETUP_CONFIRM_BUTTON}
          >
            Confirm
          </button>
        </p>
      </form>
      {!!formError && (
        <p className="has-text-danger" data-testid={TEST_ID_MFA_SETUP_VALIDATION_ERROR}>
          {formError}
        </p>
      )}
    </>
  );
};

const MFASetup = (): React.JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);

  // Local state
  const [qrCode, setQrCode] = useState<string>("");
  const [qrError, setQrError] = useState<boolean>(false);

  useEffect(() => {
    const getQrCode = async (): Promise<void> => {
      try {
        const { sharedSecret: secret } = await setUpTOTP();
        const issuer = encodeURI(getIssuer());
        setQrCode(`otpauth://totp/${user.username}?secret=${secret}&issuer=${issuer}`);
      } catch (error) {
        console.error(error);
        setQrError(true);
      }
    };
    getQrCode();
  }, []);

  return (
    <div className="content">
      <p>
        Enable multi-factor authentication (MFA) to add an extra layer of security to your
        account.
      </p>
      <p>
        If you don’t already have one, install a one-time password authenticator app on
        your mobile device. We recommend{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://support.google.com/accounts/answer/1066447"
        >
          Google Authenticator
        </a>
        .
      </p>
      <div className="columns mt-2">
        <div className="column">
          <h4>1. Scan the QR code</h4>
          {qrError ? (
            <p className="has-text-danger">Failed to retrieve QR code.</p>
          ) : (
            <>
              <p>Use your one-time password authenticator app to scan this QR code.</p>
              <p className="image is-128x128 has-background-white-ter">
                {!!qrCode && (
                  <QRCodeSVG value={qrCode} data-testid={TEST_ID_MFA_SETUP_QR_CODE} />
                )}
              </p>
            </>
          )}
        </div>
        <div className="column">
          <h4>2. Complete MFA setup</h4>
          <p>
            Enter the six-digit numeric code from your authenticator app to complete MFA
            setup.
          </p>
          <ChallengeAnswerForm />
        </div>
      </div>
    </div>
  );
};

export default MFASetup;
