import React from "react";

interface CaseCardInfoProps {
  label: string;
  value: string;
}

const CaseCardInfo = ({ label, value }: CaseCardInfoProps): React.JSX.Element => {
  return (
    <>
      <p className="cy-case-card__label">{label}</p>
      <p className="cy-case-card__value">{value}</p>
    </>
  );
};

export default CaseCardInfo;
