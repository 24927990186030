import { format, parse } from "date-fns";
import React from "react";

import { Answer, CaseType, ICaseData } from "../../types/case";

export const TEST_ID_BARRETTS_CLINICAL_INFO_SECTION = "BarrettsClinicalInfoSection";
export const TEST_ID_BARRETTS_CLINICAL_INFO_HEADING = "BarrettsClinicalInfoHeading";
export const TEST_ID_PRAGUE_CLASSIFICATION = "PragueClassification";
export const TEST_ID_DYSPLASIA_GRADE = "DysplasiaGrade";
export const TEST_ID_LAST_ENDOSCOPY_DATE = "LastEndoscopyDate";

interface BarrettsHistoryProps {
  caseData: ICaseData;
  caseType: CaseType;
}

const BarrettsHistory = ({
  caseData,
  caseType,
}: BarrettsHistoryProps): React.JSX.Element | null => {
  const {
    circumferentialLength,
    maximalLength,
    hadDysplasia,
    dysplasiaGrade,
    hadEndoscopicTreatment,
    isTakingPPI,
    smokingHistory,
  } = caseData;

  if (caseType !== CaseType.SURVEILLANCE) return null;

  const pragueClassification: string =
    circumferentialLength !== undefined || maximalLength !== undefined
      ? `C${circumferentialLength ?? "-"} M${maximalLength ?? "-"}`
      : Answer.UNANSWERED;

  const dateLastEndoscopy: string = caseData.dateLastEndoscopy
    ? format(parse(caseData.dateLastEndoscopy, "yyyy-MM", new Date()), "MM/yyyy")
    : Answer.UNANSWERED;

  return (
    <section data-testid={TEST_ID_BARRETTS_CLINICAL_INFO_SECTION}>
      <h4
        className="title is-6 mb-4"
        data-testid={TEST_ID_BARRETTS_CLINICAL_INFO_HEADING}
      >
        Clinical information
      </h4>
      <dl>
        <div className="cy-data-list__item">
          <dt>Prague classification</dt>
          <dd data-testid={TEST_ID_PRAGUE_CLASSIFICATION}>{pragueClassification}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>Ever had dysplasia?</dt>
          <dd>{hadDysplasia}</dd>
        </div>
        {hadDysplasia === Answer.YES && (
          <div className="cy-data-list__item">
            <dt>Dysplasia grade</dt>
            <dd data-testid={TEST_ID_DYSPLASIA_GRADE}>{dysplasiaGrade}</dd>
          </div>
        )}
        {!!hadEndoscopicTreatment && (
          <div className="cy-data-list__item">
            <dt>Ever had endoscopic treatment?</dt>
            <dd>{hadEndoscopicTreatment}</dd>
          </div>
        )}
        <div className="cy-data-list__item">
          <dt>Last diagnostic endoscopy</dt>
          <dd data-testid={TEST_ID_LAST_ENDOSCOPY_DATE}>{dateLastEndoscopy}</dd>
        </div>
        {!!isTakingPPI && (
          <div className="cy-data-list__item">
            <dt>Is the patient taking PPI?</dt>
            <dd>{isTakingPPI}</dd>
          </div>
        )}
        {!!smokingHistory && (
          <div className="cy-data-list__item">
            <dt>Smoking history</dt>
            <dd>{smokingHistory}</dd>
          </div>
        )}
      </dl>
    </section>
  );
};

export default BarrettsHistory;
