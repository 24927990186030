import React from "react";

import { IFormContents, IQuestion } from "../../types/builder";
import NumberPicker from "./NumberPicker";

interface Tff3PositiveGroupsProps {
  question: IQuestion;
  answers: IFormContents;
}

export const MORE_THAN_TEN = "MoreThanTen";

const Tff3PositiveGroups = ({
  question,
  answers,
}: Tff3PositiveGroupsProps): React.JSX.Element | null => {
  if (question.name === "tff3PositiveGroupsSlideSectionOne") {
    return (
      <NumberPicker
        id={question.name}
        min={0}
        max={10}
        value={answers.tff3PositiveGroupsSlideSectionOne ?? ""}
        onChange={question.onChange}
      />
    );
  }
  if (question.name === "tff3PositiveGroupsSlideSectionTwo") {
    return (
      <NumberPicker
        id={question.name}
        min={0}
        max={10}
        value={answers.tff3PositiveGroupsSlideSectionTwo ?? ""}
        onChange={question.onChange}
      />
    );
  }
  return null;
};

export default Tff3PositiveGroups;
