import React from "react";
import { AuthorisedMicroReport } from "src/types/case";

import { IFormContents } from "../../types/builder";
import Amendment from "./Amendment";
import Conclusion from "./Conclusion";
import Metadata from "./Metadata";
import Recommendation from "./Recommendation";
import SnomedCodes from "./SnomedCodes";

interface ReportPreviewProps {
  answers: IFormContents;
  isMicroAmendment: boolean;
  previousReport?: AuthorisedMicroReport;
}

const ReportPreview = ({
  answers,
  isMicroAmendment,
  previousReport,
}: ReportPreviewProps): React.JSX.Element => {
  return (
    <div id="reportHtml" className="content">
      <Amendment
        answers={answers}
        isMicroAmendment={isMicroAmendment}
        previousReport={previousReport}
      />
      <Metadata answers={answers} />
      <Conclusion answers={answers} />
      <Recommendation answers={answers} />
      <SnomedCodes answers={answers} />
    </div>
  );
};

export default ReportPreview;
