import React from "react";

import {
  AdequateSample,
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  SquamousAbnormality,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "../../types/answers";
import { IFormContents } from "../../types/builder";
import { MORE_THAN_TEN } from "../questions/Tff3PositiveGroups";
import Placeholder from "./Placeholder";
import SectionHeading from "./SectionHeading";
import SectionSpacer from "./SectionSpacer";

export const TEST_ID_REPORT_PREVIEW_SQUAMOUS_CELLS = "ReportPreviewSquamousCells";
export const TEST_ID_REPORT_PREVIEW_COLUMNAR_CELLS = "ReportPreviewColumnarCells";

interface MetadataProps {
  answers: IFormContents;
}

export const squamousAdequacyLabels: Record<SquamousAbnormality, string> = {
  [SquamousAbnormality.NO]: "Yes",
  [SquamousAbnormality.YES]: "Yes",
  [SquamousAbnormality.INADEQUATE]: "No",
};

export const squamousAtypiaLabels: Record<SquamousAtypia, string> = {
  [SquamousAtypia.NO]: "No",
  [SquamousAtypia.UNCERTAIN_SIGNIFICANCE]:
    "Yes, in sheets of squamous epithelium. This is of uncertain significance.",
  [SquamousAtypia.SUSPECTED_DYSPLASIA]:
    "Yes, in sheets of squamous epithelium. Dysplasia is suspected.",
};

export const squamousP53StainingLabels: Record<SquamousP53Staining, string> = {
  [SquamousP53Staining.NORMAL]: "No (null pattern not excluded)",
  [SquamousP53Staining.EQUIVOCAL]: "Equivocal staining pattern",
  [SquamousP53Staining.ABERRANT]: "Yes (overexpression)",
};

export const columnarGroupsLabels: Record<ColumnarGroups, string> = {
  [ColumnarGroups.NONE]: "0",
  [ColumnarGroups.ONETOFOUR]: "1-4 (lab use only)",
  [ColumnarGroups.FIVEORMORE]: "5+ (lab use only)",
};

export const tff3StainingLabels: Record<Tff3Staining, string> = {
  [Tff3Staining.NEGATIVE]: "No",
  [Tff3Staining.EQUIVOCAL]: "Equivocal staining pattern",
  [Tff3Staining.POSITIVE]: "Yes",
};

export const columnarAtypiaLabels: Record<ColumnarAtypia, string> = {
  [ColumnarAtypia.NO]: "No",
  [ColumnarAtypia.UNCERTAIN_SIGNIFICANCE]:
    "Yes, in occasional groups. This is of uncertain significance. No definite high grade features are seen.",
  [ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE]:
    "Yes, in occasional groups. Dysplasia is suspected but it is difficult to grade.",
  [ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA]:
    "Yes, in occasional groups. High grade dysplasia is suspected.",
  [ColumnarAtypia.HIGH_GRADE_DYSPLASIA]:
    "Yes, in occasional groups. This is in keeping with high grade dysplasia.",
  [ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA]:
    "Yes, in occasional groups. The features are those of at least high grade dysplasia.",
};

export const columnarP53StainingLabels: Record<ColumnarP53Staining, string> = {
  [ColumnarP53Staining.NORMAL]: "No (null pattern not excluded)",
  [ColumnarP53Staining.EQUIVOCAL]: "Equivocal staining pattern",
  [ColumnarP53Staining.ABERRANT]: "Yes (overexpression)",
};

/**
 * Nesting unordered lists would render correctly in PathKit. However, the
 * sanitised HTML would include an empty <li> element which gets converted
 * to an empty <p> for the LIMS HTML, adding unwanted space. So instead we
 * style indented list items manually, for now.
 */
const positiveGroupsListItem = (
  section: string,
  value: string | undefined
): React.JSX.Element => {
  return (
    <li style={{ listStyleType: "circle", marginLeft: "1.5rem" }}>
      Section {section} = <b>{value === MORE_THAN_TEN ? "10+" : value}</b>
    </li>
  );
};

const Metadata = ({
  answers: {
    adequateSample,
    squamousAbnormality,
    squamousAtypia,
    squamousP53Staining,
    columnarGroups,
    tff3Staining,
    tff3PositiveGroupsSlideSectionOne,
    tff3PositiveGroupsSlideSectionTwo,
    columnarAtypia,
    columnarP53Staining,
  },
}: MetadataProps): React.JSX.Element | null => {
  if (adequateSample !== AdequateSample.YES) return null;
  return (
    <>
      <section data-testid={TEST_ID_REPORT_PREVIEW_SQUAMOUS_CELLS}>
        <SectionHeading>Squamous cells</SectionHeading>
        {!squamousAbnormality && !squamousAtypia && !squamousP53Staining ? (
          <Placeholder />
        ) : (
          <ul>
            {!!squamousAbnormality && (
              <li>
                Squamous cellularity adequate for diagnosis:{" "}
                <b>{squamousAdequacyLabels[squamousAbnormality]}</b>
              </li>
            )}
            {!!squamousAtypia && (
              <li>
                Squamous atypia: <b>{squamousAtypiaLabels[squamousAtypia]}</b>
              </li>
            )}
            {!!squamousP53Staining && (
              <li>
                Aberrant squamous p53 staining:{" "}
                <b>{squamousP53StainingLabels[squamousP53Staining]}</b>
              </li>
            )}
          </ul>
        )}
      </section>
      <SectionSpacer />
      <section data-testid={TEST_ID_REPORT_PREVIEW_COLUMNAR_CELLS}>
        <SectionHeading>Columnar cells</SectionHeading>
        {!columnarGroups && !tff3Staining && !columnarAtypia && !columnarP53Staining ? (
          <Placeholder />
        ) : (
          <ul>
            {!!columnarGroups && (
              <li>
                Columnar cell groups / clumps:{" "}
                <b>{columnarGroupsLabels[columnarGroups]}</b>
              </li>
            )}
            {!!tff3Staining && (
              <>
                <li>
                  Is the TFF3 stain positive? <b>{tff3StainingLabels[tff3Staining]}</b>
                </li>
                {tff3Staining === Tff3Staining.POSITIVE && (
                  <>
                    <li>
                      No. of positive groups per slide <b>(lab use only)</b>
                    </li>
                    {positiveGroupsListItem("1", tff3PositiveGroupsSlideSectionOne)}
                    {positiveGroupsListItem("2", tff3PositiveGroupsSlideSectionTwo)}
                  </>
                )}
              </>
            )}
            {!!columnarAtypia && (
              <li>
                Columnar atypia: <b>{columnarAtypiaLabels[columnarAtypia]}</b>
              </li>
            )}
            {!!columnarP53Staining && (
              <li>
                Aberrant columnar p53 staining:{" "}
                <b>{columnarP53StainingLabels[columnarP53Staining]}</b>
              </li>
            )}
          </ul>
        )}
      </section>
      <SectionSpacer />
    </>
  );
};

export default Metadata;
