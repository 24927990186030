import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from "date-fns";
import React from "react";

export const TEST_ID_AUTHORISED_INDICATOR = "AuthorisedIndicator";

interface AuthorisedIndicatorProps {
  reportSubmitted?: string;
  versionId?: string;
}

const AuthorisedIndicator = ({
  reportSubmitted,
  versionId,
}: AuthorisedIndicatorProps): React.JSX.Element => {
  const label = reportSubmitted
    ? "Authorised " + format(reportSubmitted, "d MMM yyyy")
    : "Authorised";
  return (
    <span
      className="tag is-rounded is-white"
      data-testid={`AuthorisedIndicator${versionId ?? ""}`}
    >
      <FontAwesomeIcon className="mr-1" icon={faLock} />
      {label}
    </span>
  );
};

export default AuthorisedIndicator;
