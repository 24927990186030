import React from "react";

import { ManagementPriority } from "../../types/builder";

export const TEST_ID_MANAGEMENT_PRIORITY_INDICATOR = "ManagementPriorityIndicator";

export const managementPriorityLabelMap: {
  [key in ManagementPriority]: {
    text: string;
    color: string;
  };
} = {
  [ManagementPriority.URGENT]: {
    text: "Urgent",
    color: "danger-dark",
  },
  [ManagementPriority.INADEQUATE]: {
    text: "Inadequate",
    color: "danger-dark",
  },
  [ManagementPriority.ROUTINE]: {
    text: "Routine",
    color: "link-dark",
  },
  [ManagementPriority.NORMAL]: {
    text: "Normal",
    color: "success",
  },
};

const ManagementPriorityIndicator = ({
  priority,
}: {
  priority?: ManagementPriority;
}): React.JSX.Element | null => {
  if (!priority) return null;

  const { text, color } = managementPriorityLabelMap[priority];

  return (
    <p
      className="has-text-weight-semibold"
      data-testid={TEST_ID_MANAGEMENT_PRIORITY_INDICATOR}
    >
      Summary: <span className={`has-text-${color}`}>{text}</span>
    </p>
  );
};

export default ManagementPriorityIndicator;
