import { intersection, uniqBy } from "lodash";
import React from "react";

import { getNumberArray } from "../../helpers/numbers";
import {
  AdequateSample,
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  InadequateSampleReason,
  KnownBarretts,
  OtherAbnormalities,
  P53Stain,
  SquamousAbnormality,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "../../types/answers";
import { IConditionalContent, IFormContents } from "../../types/builder";
import {
  MAX_PRAGUE_MEASUREMENT,
  PRAGUE_CLASSIFICATION_NOT_PROVIDED,
  SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
} from "../questions/PragueClassification";
import Placeholder from "./Placeholder";
import SectionHeading from "./SectionHeading";
import SectionSpacer from "./SectionSpacer";

export const TEST_ID_REPORT_PREVIEW_CONCLUSION = "ReportPreviewConclusion";

interface ConclusionPhrase extends IConditionalContent {
  phrase: string;
}

interface ConclusionProps {
  answers: IFormContents;
}

const Conclusion = ({ answers }: ConclusionProps): React.JSX.Element => {
  const conclusionBullets: ConclusionPhrase[] = [
    {
      phrase: "INSUFFICIENT FOR PATHOLOGICAL REVIEW",
      conditions: {
        adequateSample: [AdequateSample.NO],
      },
    },
    {
      phrase: "SQUAMOUS ATYPIA OF UNCERTAIN SIGNIFICANCE",
      conditions: {
        squamousAtypia: [SquamousAtypia.UNCERTAIN_SIGNIFICANCE],
      },
    },
    {
      phrase: "SQUAMOUS DYSPLASIA SUSPECTED",
      conditions: {
        squamousAtypia: [SquamousAtypia.SUSPECTED_DYSPLASIA],
      },
    },
    {
      phrase: "EQUIVOCAL SQUAMOUS p53",
      conditions: {
        squamousP53Staining: [SquamousP53Staining.EQUIVOCAL],
      },
    },
    {
      phrase: "ABERRANT SQUAMOUS p53",
      conditions: {
        squamousP53Staining: [SquamousP53Staining.ABERRANT],
      },
    },
    {
      phrase: "NEGATIVE FOR INTESTINAL METAPLASIA",
      conditions: {
        tff3Staining: [Tff3Staining.NEGATIVE],
      },
    },
    {
      phrase: "EQUIVOCAL FOR INTESTINAL METAPLASIA",
      conditions: {
        tff3Staining: [Tff3Staining.EQUIVOCAL],
      },
    },
    {
      phrase: "POSITIVE FOR INTESTINAL METAPLASIA",
      conditions: {
        tff3Staining: [Tff3Staining.POSITIVE],
      },
    },
    {
      phrase: "NO COLUMNAR ATYPIA",
      conditions: {
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase: "COLUMNAR ATYPIA OF UNCERTAIN SIGNIFICANCE",
      conditions: {
        columnarAtypia: [ColumnarAtypia.UNCERTAIN_SIGNIFICANCE],
      },
    },
    {
      phrase: "COLUMNAR DYSPLASIA SUSPECTED BUT DIFFICULT TO GRADE",
      conditions: {
        columnarAtypia: [ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE],
      },
    },
    {
      phrase: "HIGH GRADE COLUMNAR DYSPLASIA SUSPECTED",
      conditions: {
        columnarAtypia: [ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase: "HIGH GRADE COLUMNAR DYSPLASIA",
      conditions: {
        columnarAtypia: [ColumnarAtypia.HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase: "AT LEAST HIGH GRADE COLUMNAR DYSPLASIA",
      conditions: {
        columnarAtypia: [ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase: "NORMAL COLUMNAR p53",
      conditions: {
        columnarP53Staining: [ColumnarP53Staining.NORMAL],
      },
    },
    {
      phrase: "EQUIVOCAL COLUMNAR p53",
      conditions: {
        columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL],
      },
    },
    {
      phrase: "ABERRANT COLUMNAR p53",
      conditions: {
        columnarP53Staining: [ColumnarP53Staining.ABERRANT],
      },
    },
    {
      phrase: "EVIDENCE OF ULCERATION",
      conditions: {
        otherAbnormalities: [OtherAbnormalities.ULCERATION],
      },
    },
  ];

  const conclusionParagraphs: ConclusionPhrase[] = [
    // Inadequate overall sample
    {
      phrase:
        "Unfortunately there were too few squamous cells in the sample to provide a reliable result.",
      conditions: {
        inadequateSampleReason: [InadequateSampleReason.INSUFFICIENT],
      },
    },
    {
      phrase:
        "The sample is heavily contaminated with food material, which hinders assessment.",
      conditions: {
        inadequateSampleReason: [InadequateSampleReason.FOOD],
      },
    },
    // Squamous cells
    {
      phrase: "There is no squamous atypia.",
      conditions: {
        squamousAbnormality: [SquamousAbnormality.NO],
      },
    },
    {
      phrase: "Squamous atypia can be reactive or can indicate dysplasia or cancer.",
      conditions: {
        squamousAtypia: [SquamousAtypia.UNCERTAIN_SIGNIFICANCE],
      },
    },
    {
      phrase: "A squamous dysplastic lesion of the oesophagus / oropharynx is suspected.",
      conditions: {
        squamousAtypia: [SquamousAtypia.SUSPECTED_DYSPLASIA],
      },
    },
    {
      phrase:
        "Squamous p53 staining is equivocal, therefore abnormal expression can neither be confirmed nor excluded.",
      conditions: {
        squamousAtypia: [SquamousAtypia.NO, SquamousAtypia.UNCERTAIN_SIGNIFICANCE],
        squamousP53Staining: [SquamousP53Staining.EQUIVOCAL],
      },
    },
    {
      phrase: "Aberrant squamous p53 can be associated with squamous dysplasia.",
      conditions: {
        squamousAtypia: [SquamousAtypia.NO, SquamousAtypia.UNCERTAIN_SIGNIFICANCE],
        squamousP53Staining: [SquamousP53Staining.ABERRANT],
      },
    },
    // Columnar cells
    {
      phrase: "There is no intestinal metaplasia or columnar atypia.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        hasP53Stain: [P53Stain.NO],
        tff3Staining: [Tff3Staining.NEGATIVE],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase: "There is no intestinal metaplasia, columnar atypia or p53 abnormality.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        hasP53Stain: [P53Stain.YES],
        tff3Staining: [Tff3Staining.NEGATIVE],
        columnarP53Staining: [ColumnarP53Staining.NORMAL],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase:
        "The history of Barrett’s is noted. The lack of intestinal metaplasia here may reflect random sampling of the Barrett’s segment.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        tff3Staining: [Tff3Staining.NEGATIVE],
        columnarP53Staining: [ColumnarP53Staining.NORMAL],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase:
        "Barrett’s oesophagus with intestinal metaplasia or intestinal metaplasia elsewhere, for example, in the oesophago-gastric junction or stomach can neither be confirmed nor excluded on the basis of this sample.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        tff3Staining: [Tff3Staining.EQUIVOCAL],
      },
    },
    {
      phrase:
        "Intestinal metaplasia cannot be confirmed or excluded on the basis of this sample.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        tff3Staining: [Tff3Staining.EQUIVOCAL],
      },
    },
    {
      phrase:
        "This patient may have Barrett’s oesophagus or intestinal metaplasia elsewhere, for example at the gastro-oesophageal junction or stomach.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        tff3Staining: [Tff3Staining.POSITIVE],
      },
    },
    {
      phrase: "This patient has intestinal metaplasia.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        tff3Staining: [Tff3Staining.POSITIVE],
      },
    },
    {
      phrase:
        "There is no dysplasia or atypia noted but focal changes can be missed due to sampling bias.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        columnarP53Staining: [ColumnarP53Staining.NORMAL],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase:
        "Columnar atypia could represent reflux related reactive changes or dysplasia.",
      conditions: {
        columnarAtypia: [ColumnarAtypia.UNCERTAIN_SIGNIFICANCE],
      },
    },
    {
      phrase: "Columnar dysplasia is suspected but it is difficult to grade.",
      conditions: {
        columnarAtypia: [ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE],
      },
    },
    {
      phrase: "High grade columnar dysplasia is suspected.",
      conditions: {
        columnarAtypia: [ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase: "There is high grade columnar dysplasia.",
      conditions: {
        columnarAtypia: [ColumnarAtypia.HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase: "There is at least high grade columnar dysplasia.",
      conditions: {
        columnarAtypia: [ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA],
      },
    },
    {
      phrase:
        "No atypia was observed but aberrant columnar p53 can be associated with dysplasia and risk of disease progression.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        columnarP53Staining: [ColumnarP53Staining.ABERRANT],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase:
        "There is no atypia noted but focal changes can be missed and aberrant columnar p53 can be associated with dysplasia and risk of disease progression.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        columnarP53Staining: [ColumnarP53Staining.ABERRANT],
        columnarAtypia: [ColumnarAtypia.NO],
      },
    },
    {
      phrase:
        "Columnar p53 staining is equivocal, therefore abnormal expression can neither be confirmed nor excluded.",
      conditions: {
        columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL],
      },
    },
    {
      phrase:
        "Aberrant p53 can be associated with dysplasia and risk of disease progression.",
      conditions: {
        columnarP53Staining: [ColumnarP53Staining.ABERRANT],
        columnarAtypia: [ColumnarAtypia.UNCERTAIN_SIGNIFICANCE],
      },
    },
    // Insufficient
    {
      phrase:
        "Unfortunately there were no columnar groups present in the sample, therefore it is insufficient for the assessment of intestinal metaplasia or Barrett’s oesophagus. The lack of columnar cells may indicate that the cell collection device has not reached the stomach.",
      conditions: {
        knownBarretts: [KnownBarretts.NO],
        columnarGroups: [ColumnarGroups.NONE],
      },
    },
    {
      phrase:
        "Unfortunately there were no columnar groups present in the sample, therefore intestinal metaplasia or columnar atypia cannot be assessed, so it is best regarded as insufficient for the assessment of the Barrett’s component. The lack of columnar cells may indicate that the cell collection device has not reached the stomach.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        columnarGroups: [ColumnarGroups.NONE],
      },
    },
    /**
     * The next two phrases are duplicated because they must be shown when
     * Circumferential (C) or Maximal (M) is longer than 2cm. The phrases
     * are based on the 'Scant columnar epithelium (1-4) small groups with
     * a Barrett’s history C / M > 2cm' paragraphs in the report templates.
     */
    {
      phrase:
        "Unfortunately there were too few columnar groups present to confidently rule out atypia, therefore it is best regarded as insufficient for diagnosis.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        pragueCircumferential: getNumberArray(
          SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
          MAX_PRAGUE_MEASUREMENT
        ),
        columnarGroups: [ColumnarGroups.ONETOFOUR],
      },
    },
    {
      phrase:
        "Unfortunately there were too few columnar groups present to confidently rule out atypia, therefore it is best regarded as insufficient for diagnosis.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        pragueMaximal: getNumberArray(
          SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
          MAX_PRAGUE_MEASUREMENT
        ),
        columnarGroups: [ColumnarGroups.ONETOFOUR],
      },
    },
    {
      phrase:
        "If the segment length is greater than 2cm then there are too few columnar groups to confidently rule out atypia, therefore it is best regarded as insufficient for diagnosis.",
      conditions: {
        knownBarretts: [KnownBarretts.YES],
        columnarGroups: [ColumnarGroups.ONETOFOUR],
        pragueCircumferential: [PRAGUE_CLASSIFICATION_NOT_PROVIDED],
        pragueMaximal: [PRAGUE_CLASSIFICATION_NOT_PROVIDED],
      },
    },
    // Other abnormalities
    {
      phrase:
        "Evidence of ulceration: acid-suppression may need increasing. If clinical symptoms present consider endoscopy referral (if not already advised below).",
      conditions: {
        otherAbnormalities: [OtherAbnormalities.ULCERATION],
      },
    },
  ];

  const getMatchingPhrases = (collection: ConclusionPhrase[]): string[] => {
    return (
      uniqBy(
        collection.filter((phrase: ConclusionPhrase) => {
          return Object.entries(phrase.conditions).every(([key, condition]) => {
            const answer = answers[key as keyof typeof answers];
            if (Array.isArray(answer)) {
              // Other abnormalities
              return !!intersection(answer, condition).length;
            } else {
              // Everything else
              return condition?.includes(answer) ?? false;
            }
          });
        }),
        "phrase"
      ).map((matched: ConclusionPhrase) => matched.phrase) ?? []
    );
  };

  const matchedConclusionBullets = getMatchingPhrases(conclusionBullets);
  const matchedConclusionParagraphs = getMatchingPhrases(conclusionParagraphs);

  return (
    <section data-testid={TEST_ID_REPORT_PREVIEW_CONCLUSION}>
      <SectionHeading>Conclusion</SectionHeading>
      {!!matchedConclusionBullets.length && (
        <ul>
          {matchedConclusionBullets.map((phrase: string) => (
            <li key={phrase}>
              <b>{phrase}</b>
            </li>
          ))}
        </ul>
      )}
      {matchedConclusionParagraphs.length ? (
        matchedConclusionParagraphs.map((phrase: string) => (
          // Tableau uses class="pathkit" to identify PathKit reports
          <p key={phrase} className="pathkit">
            {phrase}
          </p>
        ))
      ) : (
        <Placeholder />
      )}
      <SectionSpacer />
    </section>
  );
};

export default Conclusion;
