import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router";

export const TEST_ID_OPEN_MICRO_REPORT_LINK = "OpenMicroReportLink";

interface MicroReportLinkProps {
  caseUrl: string;
}

const MicroReportLink = ({ caseUrl }: MicroReportLinkProps): React.JSX.Element => {
  return (
    <Link
      to={caseUrl}
      className="button is-ghost is-small pt-0 mb-1 pr-4 is-flex is-justify-content-flex-end"
      data-testid={TEST_ID_OPEN_MICRO_REPORT_LINK}
    >
      <FontAwesomeIcon icon={faEdit} className="mr-2" /> Open micro report
    </Link>
  );
};

export default MicroReportLink;
