import React from "react";

import { IAnswer, IFormContents, IQuestion } from "../../types/builder";
import Choice from "./Choice";
import PragueClassification from "./PragueClassification";
import Tff3PositiveGroups from "./Tff3PositiveGroups";

interface RadioGroupProps {
  question: IQuestion;
  answers: IFormContents;
}

const RadioGroup = ({ question, answers }: RadioGroupProps): React.JSX.Element => {
  const groupId = `${question.name}Question`;
  return (
    <div
      role="radiogroup"
      className="mb-4"
      data-testid={groupId}
      aria-labelledby={groupId}
    >
      <h3 className="title is-6 mb-3" id={groupId}>
        {question.title}
      </h3>
      <PragueClassification question={question} answers={answers} />
      <Tff3PositiveGroups question={question} answers={answers} />
      {question.options
        .filter((answer: IAnswer) => !answer.hidden)
        .map((answer: IAnswer) => {
          return (
            <Choice
              type="radio"
              key={answer.value}
              label={answer.label}
              value={answer.value}
              name={question.name}
              checked={answer.value === question.selected}
              disabled={answer.disabled}
              onChange={question.onChange}
            />
          );
        })}
    </div>
  );
};

export default RadioGroup;
