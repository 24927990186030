import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router";

import { TEST_ID_CASE_LIST_LINK } from "../components/dialogs/SaveReportDialog";
import LabWork from "../components/lab/LabWork";
import PatientDetails from "../components/patient/PatientDetails";
import PreviousReports from "../components/report/PreviousReports";
import { Special } from "../schemas/ApiSchema";
import { AuthorisedMicroReport, CaseState, CaseType, ICaseData } from "../types/case";

export const TEST_ID_READ_ONLY_MODE = "ReadOnlyMode";
export const TEST_ID_READ_ONLY_MODE_MICROCOPY = "ReadOnlyModeMicrocopy";

interface ReadOnlyModeProps {
  caseData?: ICaseData;
  caseState?: CaseState;
  caseType: CaseType | null;
  specials: Special[];
  authorisedReports: AuthorisedMicroReport[];
}

const ReadOnlyMode = ({
  caseData,
  caseState,
  caseType,
  specials,
  authorisedReports,
}: ReadOnlyModeProps): React.JSX.Element => {
  // Cases in read-only mode are not always authorised. Cases that are awaiting slides and non-standard cases
  // reopened for amendment are also not editable in PathKit and should therefore be in read-only mode
  const isAuthorised =
    caseState === CaseState.LOCKED || caseState === CaseState.WITH_THE_LAB;
  const reportOrAmendment = authorisedReports.length > 1 ? "amendment" : "report";

  return (
    <div className="columns is-desktop" data-testid={TEST_ID_READ_ONLY_MODE}>
      <div
        className="column is-two-fifths-desktop"
        data-testid={TEST_ID_READ_ONLY_MODE_MICROCOPY}
      >
        <section className="content">
          <h3 className="title is-4">
            {isAuthorised && <FontAwesomeIcon className="mr-2" icon={faCheck} />}
            {isAuthorised
              ? `This ${reportOrAmendment} has been authorised`
              : "This case is not editable in PathKit"}
          </h3>
          <p>
            For {isAuthorised ? "subsequent amendments" : "further information"}, please
            contact our clinical and lab teams via{" "}
            <a href="mailto:support@cytedhealth.com">support@cytedhealth.com</a>.
          </p>
          <p>
            To report on another case, please{" "}
            <Link to="/" data-testid={TEST_ID_CASE_LIST_LINK}>
              return to the case list
            </Link>
            .
          </p>
        </section>
      </div>
      <div className="column is-three-fifths-desktop">
        <LabWork
          caseData={caseData}
          caseType={caseType}
          canEditMicro={false}
          specials={specials}
        />
        <PatientDetails caseData={caseData} caseType={caseType} />
        <PreviousReports authorisedReports={authorisedReports} />
      </div>
    </div>
  );
};

export default ReadOnlyMode;
