import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEvent, useState } from "react";

import Box from "./Box";

interface AccordionProps {
  id: string;
  title: string;
  addon?: React.ReactNode;
  expanded?: boolean;
  children: React.ReactNode;
}

const Accordion = ({
  id,
  title,
  addon,
  expanded = false,
  children,
}: AccordionProps): React.JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const toggleExpanded = (e: MouseEvent) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  return (
    <Box padding={0} testId={`${id}Accordion`}>
      <h3
        className={classNames({
          "title is-4 mb-0": true,
          "is-flex is-justify-content-space-between": !!addon,
        })}
      >
        <a
          href=""
          role="button"
          className="px-4 py-5 is-flex is-align-items-center has-text-grey-darker"
          data-testid={`${id}Toggle`}
          onClick={toggleExpanded}
        >
          <FontAwesomeIcon
            icon={faCaretRight}
            className={classNames({
              "icon mr-3": true,
              "fa-rotate-90": isExpanded,
            })}
            style={{ transition: "transform .15s" }}
          />
          <span data-testid={`${id}AccordionTitle`}>{title}</span>
        </a>
        {addon && <div className="px-4 py-5">{addon}</div>}
      </h3>
      <div className="px-5 pb-5" style={{ display: isExpanded ? "block" : "none" }}>
        {children}
      </div>
    </Box>
  );
};

export default Accordion;
