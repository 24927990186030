import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import { CaseType, ICaseData } from "../../types/case";
import Accordion from "../common/Accordion";
import BarrettsHistory from "./BarrettsHistory";
import Macro from "./Macro";
import PatientInformation from "./PatientInformation";
import ProcedureNotes from "./ProcedureNotes";
import RefluxHistory from "./RefluxHistory";

interface PatientDetailsProps {
  caseData?: ICaseData;
  caseType: CaseType | null;
  expanded?: boolean;
}

export const TEST_ID_CHECK_REQUEST_FORM_TAG = "CheckRequestFormTag";

// Warn the pathologist if the request form included additional clinical
// information that can't be displayed in the 'Patient details' section
const CheckRequestFormTag = (): React.JSX.Element => {
  return (
    <span
      className="tag is-rounded is-warning"
      data-testid={TEST_ID_CHECK_REQUEST_FORM_TAG}
    >
      <FontAwesomeIcon icon={faWarning} className="mr-1" />
      Check request form
    </span>
  );
};

const PatientDetails = ({
  caseData,
  caseType,
  expanded = false,
}: PatientDetailsProps): React.JSX.Element | null => {
  if (!caseData || !caseType) return null;
  return (
    <Accordion
      expanded={expanded}
      title="Patient details"
      id="patientDetails"
      addon={caseData.hasAdditionalClinicalInformation && <CheckRequestFormTag />}
    >
      <div
        className="columns is-multiline is-variable is-5"
        style={{ fontSize: "0.875rem" }}
      >
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <PatientInformation caseData={caseData} />
        </div>
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <ProcedureNotes caseData={caseData} />
        </div>
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <RefluxHistory caseData={caseData} caseType={caseType} />
          <BarrettsHistory caseData={caseData} caseType={caseType} />
        </div>
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <Macro macro={caseData.macro} />
        </div>
      </div>
    </Accordion>
  );
};

export default PatientDetails;
