export enum CaseType {
  REFLUX = "Reflux",
  SURVEILLANCE = "Barrett’s surveillance",
}

export type AllowedSlides = "TFF3" | "HAndE" | "P53";

export enum Answer {
  MALE = "Male",
  FEMALE = "Female",
  OTHER = "Other",
  YES = "Yes",
  NO = "No",
  UNKNOWN = "Don’t know",
  UNANSWERED = "Not provided",
  NEVER = "Never",
  FORMER = "Former",
  CURRENT = "Current",
  LOW = "Low",
  HIGH = "High",
  INDEFINITE = "Indefinite",
  NONE = "None",
  ONE = "1",
  TWO = "2",
}

export interface AuthorisedMicroReport {
  versionId: string;
  microHtml: string;
  publicationTimestamp: string;
  reasonForAmendment: string | null;
}

export enum CaseState {
  DELEGATED_TO_LIMS = "DelegatedToLims",
  AWAITING_SLIDES = "AwaitingSlides",
  READY_FOR_PATHOLOGIST = "ReadyForPathologist",
  REPORT_SUBMITTED = "ReportSubmitted",
  LOCKED = "Locked",
  WITH_THE_LAB = "WithTheLab",
}

export interface ICaseData {
  // Lab (CYT) number and cell preservation kit (P) ID
  labNumber: string;
  recordNumber: string;

  // Patient details
  patientIdentifier: string;
  patientIdentifierAlternative: string | null;
  patientDateOfBirth: string;
  patientDateOfBirthIsYearOnly: boolean;
  patientSex: Answer.MALE | Answer.FEMALE | Answer.OTHER;

  // Clinical information
  isTakingPPI: Answer.YES | Answer.NO | Answer.UNANSWERED;
  smokingHistory: Answer.NEVER | Answer.FORMER | Answer.CURRENT | Answer.UNANSWERED;
  hasAdditionalClinicalInformation: boolean;

  // Reflux history (TRF-1)
  hasRefluxSymptoms?: Answer.YES | Answer.NO | Answer.UNANSWERED;
  hasEoEDiagnosis?: Answer.YES | Answer.NO | Answer.UNKNOWN | Answer.UNANSWERED;

  // Barrett’s history (TRF-2)
  hadDysplasia?: Answer.YES | Answer.NO | Answer.UNKNOWN | Answer.UNANSWERED;
  dysplasiaGrade?:
    | Answer.LOW
    | Answer.HIGH
    | Answer.INDEFINITE
    | Answer.UNKNOWN
    | Answer.UNANSWERED;
  hadEndoscopicTreatment?: Answer.YES | Answer.NO | Answer.UNKNOWN | Answer.UNANSWERED;
  dateLastEndoscopy?: string;
  circumferentialLength?: number;
  maximalLength?: number;

  // Procedure notes
  procedureDate: string;
  confidentSpongeReachedStomach: Answer.YES | Answer.NO | Answer.UNANSWERED;
  didPatientSwallow: Answer.YES | Answer.NO | Answer.UNANSWERED;
  isBloodOnSponge: Answer.YES | Answer.NO | Answer.UNANSWERED;
  macro: string;

  // Lab use only
  slides: AllowedSlides[];
}
