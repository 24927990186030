import React from "react";

import { handleSignOut } from "../../helpers/auth";

export const TEST_ID_NOT_AUTHORISED_TITLE = "NotAuthorisedTitle";

const UserNotAuthorisedDialog = (): React.JSX.Element => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box content has-background-grey-lighter">
          <h3 className="title is-4" data-testid={TEST_ID_NOT_AUTHORISED_TITLE}>
            Access denied
          </h3>
          <p>Sorry, your user account is not allowed to access this case.</p>
          <p>
            Please{" "}
            <a href="" onClick={handleSignOut}>
              log out
            </a>{" "}
            and try again, or contact{" "}
            <a href="mailto:support@cytedhealth.com">support@cytedhealth.com</a> for help.
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserNotAuthorisedDialog;
