import React from "react";

interface BoxProps {
  padding?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  title?: string;
  addon?: React.ReactNode;
  testId?: string;
  children: React.ReactNode;
}

const Box = ({
  padding = 5,
  title,
  addon,
  testId,
  children,
}: BoxProps): React.JSX.Element => {
  return (
    <div className={`box p-${padding}`} data-testid={testId}>
      {title && (
        <h3 className="title is-4">
          {title}
          {addon}
        </h3>
      )}
      {children}
    </div>
  );
};

export default Box;
