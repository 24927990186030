import classNames from "classnames";
import { isNil } from "lodash";
import React from "react";

import { CaseListTab } from "../../routes/CaseList";

export const TEST_ID_CASE_LIST_TAB = "CaseListTab";

interface CaseListMenuProps {
  totalCases: Record<CaseListTab, number | undefined>;
  activeTab: CaseListTab;
  setActiveTab: (x: CaseListTab) => void;
}

interface MenuListProps {
  label: string;
  tabs: CaseListTab[];
}

const CaseListMenu = ({
  totalCases,
  activeTab,
  setActiveTab,
}: CaseListMenuProps): React.JSX.Element => {
  const handleChangeTab = (tab: CaseListTab, e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTab(tab);
  };

  const getTabLabel = (tab: CaseListTab) => {
    const count = !isNil(totalCases[tab]) ? ` (${totalCases[tab]})` : "";
    switch (tab) {
      case "ReadyForReporting":
        return "Ready for reporting" + count;
      case "WithPendingRequests":
        return "Cases with pending requests" + count;
      case "Upcoming":
        return "Upcoming cases" + count;
      case "Authorised":
        return "Recently authorised";
    }
  };

  const MenuList = ({ label, tabs }: MenuListProps): React.JSX.Element => {
    return (
      <>
        <p className="menu-label">{label}</p>
        <ul className="menu-list" role="menu">
          {tabs.map((tab) => {
            return (
              <li onClick={(e) => handleChangeTab(tab, e)} key={tab}>
                <a
                  className={classNames({
                    "is-active has-background-primary": activeTab === tab,
                  })}
                  href=""
                  role="menuitem"
                  tabIndex={0}
                  data-testid={TEST_ID_CASE_LIST_TAB + tab}
                >
                  {getTabLabel(tab)}
                </a>
              </li>
            );
          })}
        </ul>
      </>
    );
  };

  return (
    <div className="menu pr-6">
      <MenuList label="Ready" tabs={["ReadyForReporting"]} />
      <MenuList label="Not ready" tabs={["WithPendingRequests", "Upcoming"]} />
      <MenuList label="Completed" tabs={["Authorised"]} />
    </div>
  );
};

export default CaseListMenu;
