import { format } from "date-fns";
import React from "react";

export const TEST_ID_DATE_TAG = "DateTag";

interface DateTagProps {
  action: string;
  date: string;
}

const DateTag = ({ action, date }: DateTagProps): React.JSX.Element => {
  return (
    <span className="tag is-rounded" data-testid={TEST_ID_DATE_TAG}>
      {action + " " + format(date, "d MMMM yyyy")}
    </span>
  );
};

export default DateTag;
