import React from "react";

import { ICaseData } from "../../types/case";

export const TEST_ID_PROCEDURE_NOTES_SECTION = "ProcedureNotesSection";
export const TEST_ID_PROCEDURE_NOTES_HEADING = "ProcedureNotesHeading";

interface ProcedureNotesProps {
  caseData: ICaseData;
}

const ProcedureNotes = ({ caseData }: ProcedureNotesProps): React.JSX.Element => {
  const { confidentSpongeReachedStomach, didPatientSwallow, isBloodOnSponge } = caseData;

  return (
    <section className="mb-4" data-testid={TEST_ID_PROCEDURE_NOTES_SECTION}>
      <h4 className="title is-6 mb-4" data-testid={TEST_ID_PROCEDURE_NOTES_HEADING}>
        Procedure notes
      </h4>
      <dl>
        {!!confidentSpongeReachedStomach && (
          <div className="cy-data-list__item">
            <dt>Are you confident that the sponge reached the stomach?</dt>
            <dd>{confidentSpongeReachedStomach}</dd>
          </div>
        )}
        {!!didPatientSwallow && (
          <div className="cy-data-list__item">
            <dt>
              Do you think the patient swallowed the capsule so it reached their stomach?
            </dt>
            <dd>{didPatientSwallow}</dd>
          </div>
        )}
        <div className="cy-data-list__item">
          <dt>Is there blood on the sponge?</dt>
          <dd>{isBloodOnSponge}</dd>
        </div>
      </dl>
    </section>
  );
};

export default ProcedureNotes;
