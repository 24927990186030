import React from "react";

/**
 * The <div> element containing the non-breaking space is hidden by
 * CSS in PathKit's UI, but converted to <p>&nbsp;</p> when writing
 * the microHtml to the LIMS. This is how the LIMS editor achieves
 * vertical spacing between report sections.
 */

const SectionSpacer = (): React.JSX.Element => {
  return <div>&nbsp;</div>;
};

export default SectionSpacer;
