import { faArrowDownWideShort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { CaseListTab } from "src/routes/CaseList";

interface SortIndicatorProps {
  activeTab: CaseListTab;
}

const SortIndicator = ({ activeTab }: SortIndicatorProps): React.JSX.Element => {
  return (
    <p className="column is-size-7 is-italic has-text-right">
      <FontAwesomeIcon icon={faArrowDownWideShort} className="mr-1" />
      {activeTab === "Authorised" ? "Most recently updated first" : "Oldest cases first"}
    </p>
  );
};

export default SortIndicator;
