import React from "react";

export const TEST_ID_MACRO_SECTION = "MacroSection";

interface MacroProps {
  macro: string;
}

const Macro = ({ macro }: MacroProps): React.JSX.Element | null => {
  if (!macro?.trim().length) return null;
  return (
    <section data-testid={TEST_ID_MACRO_SECTION}>
      <h4 className="title is-6 mb-4">Macro</h4>
      <p>{macro}</p>
    </section>
  );
};

export default Macro;
