import { useAuthenticator } from "@aws-amplify/ui-react";
import { isBoolean } from "lodash";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import Box from "../components/common/Box";
import PageHeader from "../components/pages/PageHeader";
import ChangePassword from "../components/profile/ChangePassword";
import MFAComplete from "../components/profile/MFAComplete";
import MFASetup from "../components/profile/MFASetup";
import { SITE_TITLE } from "../helpers/strings";
import { RootState } from "../store";

const UserProfile = (): React.JSX.Element => {
  const { user } = useAuthenticator((context) => [context.user]);

  // Redux
  const { isMfaEnabled } = useSelector((state: RootState) => state.auth);

  // Page title
  const pageTitle = "User settings";
  useEffect(() => {
    document.title = `${pageTitle} | ${SITE_TITLE}`;
  }, []);

  return (
    <div className="container is-max-desktop">
      <main className="section pt-0">
        <PageHeader title={pageTitle} subtitle={user.username} />
        {isBoolean(isMfaEnabled) && (
          <Box title="Security">{isMfaEnabled ? <MFAComplete /> : <MFASetup />}</Box>
        )}
        <Box title="Change password">
          <ChangePassword />
        </Box>
      </main>
    </div>
  );
};

export default UserProfile;
