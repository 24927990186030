import React from "react";

interface LoadingBackdropProps {
  open: boolean;
  title?: string;
  subtitle?: string;
}

const LoadingBackdrop = ({
  open,
  title,
  subtitle,
}: LoadingBackdropProps): React.JSX.Element | null => {
  if (!open) return null;
  return (
    <div className="cy-loading-backdrop">
      <div className="cy-loading-backdrop__body">
        <div className="cy-loading-backdrop__loader"></div>
        <h1 className="title is-4 has-text-light">{title ?? "Loading"}</h1>
        <h2 className="subtitle has-text-light">{subtitle ?? "Just a moment..."}</h2>
      </div>
    </div>
  );
};

export default LoadingBackdrop;
