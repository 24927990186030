import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_VIEW_IN_LIMS_LINK = "ViewInLimsLink";

interface LinkToLimsProps {
  caseId: string;
}

// This can be removed after we sever ties between PathKit and the LIMS
const LinkToLims = ({ caseId }: LinkToLimsProps): React.JSX.Element => {
  const limsUrl = import.meta.env.VITE_APP_LIMS_URL + `/Casebook/Micro?CaseID=${caseId}`;
  return (
    <div className="is-flex-desktop is-justify-content-flex-end">
      <a
        href={limsUrl}
        className="button mb-3"
        role="button"
        data-testid={TEST_ID_VIEW_IN_LIMS_LINK}
      >
        <FontAwesomeIcon icon={faExternalLink} size="sm" className="mr-2" /> View in LIMS
      </a>
    </div>
  );
};

export default LinkToLims;
