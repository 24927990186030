import React from "react";

import { getReportSnomedCodes } from "../../rules/SnomedCodes";
import { IFormContents } from "../../types/builder";
import SectionHeading from "./SectionHeading";

export const TEST_ID_REPORT_PREVIEW_SNOMED_CODES = "ReportPreviewSnomedCodes";

interface SnomedCodesProps {
  answers: IFormContents;
}

const ReportSnomedCodes = ({ answers }: SnomedCodesProps): React.JSX.Element | null => {
  const snomedCodes = getReportSnomedCodes(answers);

  return (
    <section data-testid={TEST_ID_REPORT_PREVIEW_SNOMED_CODES}>
      <SectionHeading>SNOMED codes</SectionHeading>
      {/* These two CSS classes are used in the LIMS editor */}
      <table className="table table-bordered">
        <tbody>
          {snomedCodes?.map((snomedCode) => {
            return (
              <tr key={snomedCode.code}>
                <th scope="row">{snomedCode.description}</th>
                <td>{snomedCode.code}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

export default ReportSnomedCodes;
