import React from "react";

import { Special } from "../../schemas/ApiSchema";
import { CaseType, ICaseData } from "../../types/case";
import Accordion from "../common/Accordion";
import SlideLinks from "./SlideLinks";
import Specials from "./Specials";

interface LabWorkProps {
  caseData?: ICaseData;
  caseType: CaseType | null;
  canEditMicro: boolean;
  specials: Special[];
  setShowRequestSpecialDialog?: (x: boolean) => void;
  expanded?: boolean;
}
const LabWork = ({
  caseData,
  caseType,
  canEditMicro,
  specials,
  setShowRequestSpecialDialog,
  expanded = false,
}: LabWorkProps): React.JSX.Element | null => {
  if (!caseData || !caseType) return null;

  return (
    <Accordion expanded={expanded} title="Laboratory work" id="labWork">
      <div
        className="columns is-multiline is-variable is-5"
        style={{ fontSize: "0.875rem" }}
      >
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <SlideLinks labNumber={caseData.labNumber} />
        </div>
        <div className="column is-half-tablet is-full-desktop is-half-widescreen">
          <Specials
            canEditMicro={canEditMicro}
            specials={specials}
            setShowRequestSpecialDialog={setShowRequestSpecialDialog}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default LabWork;
