import React from "react";

interface SectionHeadingProps {
  children: React.ReactNode;
}

/**
 * <h3> elements are converted to <p> elements for the LIMS
 * editor, and <span> elements are converted to <b> elements
 * to make the headings bold.
 */
const SectionHeading = ({ children }: SectionHeadingProps): React.JSX.Element => {
  return (
    <h3>
      <span>{children}</span>
    </h3>
  );
};

export default SectionHeading;
